

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  padding-top: 200px
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}






















.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}


#bbg{
  height: 100%;
}
#bbd{
  height: 100%;
  background-color: #222;
  color: #eee;
  border: 0
}

.ff {
  position: fixed;
  font-size: 50px;
  bottom: 0;
  right: 25px;
  z-index: 999999;
}
.rr {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  padding:0;
  position: fixed;
  font-size: 50px;
  bottom: 70px;
  right: 33px;
  z-index: 999999;
}

#root{
  background-color: #222;
  color: #eee;
  height: 100%;
}
html, body, .container {
  height: 100%;
}
textarea.form-control {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height:auto !important;
  /* real browsers */
  height:100%;
  /* IE6: treaded as min-height*/
  min-height:100%;
  border-radius: 0%
}

.center{
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
